import React, { useContext } from "react";

import { Container, Row } from "react-bootstrap";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { ThemeContext } from "../../context/ThemeContext";
import { experienceData } from "../../data/experienceData";

export default function Experience() {
  const { theme } = useContext(ThemeContext);
  const timelineStyles = {
    dot: {
      backgroundColor: "#007bff",
      "& .MuiSvgIcon-root": {
        color: "#ffffff",
      },
    },
    connector: {
      backgroundColor:
        theme === "dark" ? "rgba(255,255,255,0.2)" : "rgba(0,0,0,0.2)",
    },
    paper: {
      backgroundColor:
        theme === "dark" ? "rgba(255,255,255,0.05)" : "rgba(0,0,0,0.05)",
      padding: "20px",
      backdropFilter: "blur(10px)",
      border:
        theme === "dark"
          ? "1px solid rgba(255,255,255,0.1)"
          : "1px solid rgba(0,0,0,0.1)",
      transition: "transform 0.3s ease",
      "&:hover": {
        transform: "translateY(-5px)",
      },
    },
    date: {
      color: theme === "dark" ? "rgba(255,255,255,0.7)" : "rgba(0,0,0,0.7)",
    },
    title: {
      color: theme === "dark" ? "#ffffff" : "#000000",
      fontWeight: 600,
    },
    description: {
      color: theme === "dark" ? "rgba(255,255,255,0.7)" : "rgba(0,0,0,0.7)",
    },
  };

  return (
    <div
      id="experience"
      className="position-relative"
      style={{
        minHeight: "100vh",
        background:
          theme === "dark"
            ? "linear-gradient(to bottom, #1a1a1a, #0a0a0a)"
            : "linear-gradient(to bottom, #f8f9fa, #e9ecef)",
        padding: "80px 0",
      }}
    >
      <Container>
        <div className="text-center mb-5">
          <h2
            className="display-4 mb-3"
            style={{
              color: theme === "dark" ? "#ffffff" : "#212529",
              letterSpacing: "2px",
              fontWeight: "bold",
            }}
          >
            Work Experience
          </h2>
          <p
            className="lead"
            style={{
              fontSize: "1.2rem",
            }}
          >
            A comprehensive overview of my professional journey, highlighting
            key roles, responsibilities, and achievements that have shaped my
            career growth.
          </p>
        </div>
        <Row>
          <Timeline position="alternate">
            {experienceData.map((item, index) => (
              <TimelineItem key={index}>
                <TimelineOppositeContent>
                  <Typography variant="body2" style={timelineStyles.date}>
                    {item.date}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot sx={timelineStyles.dot}>
                    <LaptopMacIcon />
                  </TimelineDot>
                  {index !== experienceData.length - 1 && (
                    <TimelineConnector sx={timelineStyles.connector} />
                  )}
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} sx={timelineStyles.paper}>
                    <Typography
                      variant="h6"
                      component="h1"
                      sx={timelineStyles.title}
                    >
                      {item.company}
                    </Typography>
                    <Typography sx={timelineStyles.description}>
                      {item.role}
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Row>
      </Container>
    </div>
  );
}
