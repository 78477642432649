import React, { useState, useContext } from "react";
import { Container, Row, Col, Card, Badge, Button } from "react-bootstrap";
import { certificates } from "../../data/CertificatesData";
import { ThemeContext } from "../../context/ThemeContext";

import "./../Screens/style.css";

const Certificates = () => {
  const { theme } = useContext(ThemeContext);
  const categories = [
    "All",
    ...new Set(certificates.map((cert) => cert.category)),
  ];
  const [activeCategory, setActiveCategory] = useState("All");

  // Modify the visibleItems state to be responsive
  const getInitialVisibleItems = () => {
    if (window.innerWidth >= 992) return 3; // lg and up: 3 items
    return 2; // md and sm: 2 items
  };

  const [visibleItems, setVisibleItems] = useState(getInitialVisibleItems());

  const handleShowMore = () => {
    const increment = window.innerWidth >= 992 ? 3 : 2;
    setVisibleItems((prev) => prev + increment);
  };

  const handleShowLess = () => {
    setVisibleItems(getInitialVisibleItems());
    document
      .getElementById("certificates")
      .scrollIntoView({ behavior: "smooth" });
  };

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
    setVisibleItems(getInitialVisibleItems());
  };

  const filteredCertificates =
    activeCategory === "All"
      ? certificates
      : certificates.filter((cert) => cert.category === activeCategory);

  const visibleCertificates = filteredCertificates.slice(0, visibleItems);
  const isShowingAll = visibleItems >= certificates.length;
  const isShowingMore = visibleItems > getInitialVisibleItems();

  return (
    <div
      id="certificates"
      className="position-relative"
      style={{
        minHeight: "100vh",
        background:
          theme === "dark"
            ? "linear-gradient(to bottom, #1a1a1a, #0a0a0a)"
            : "linear-gradient(to bottom, #f8f9fa, #e9ecef)",
        padding: "80px 0",
      }}
    >
      <Container>
        <div className="text-center mb-5">
          <h2
            className="display-4 mb-3"
            style={{
              color: theme === "dark" ? "#ffffff" : "#212529",
              letterSpacing: "2px",
              fontWeight: "bold",
            }}
          >
            Professional Certifications
          </h2>
          <p
            className="lead"
            style={{
              fontSize: "1.2rem",
            }}
          >
            Showcasing my commitment to continuous learning and professional
            development
          </p>
        </div>

        <div className="text-center mb-4">
          {categories.map((category) => (
            <Button
              key={category}
              variant={
                activeCategory === category ? "primary" : "outline-primary"
              }
              className="mx-2 mb-2"
              onClick={() => handleCategoryChange(category)}
              style={{
                transition: "all 0.3s ease",
                borderRadius: "4px",
                textTransform: "uppercase",
                letterSpacing: "1px",
                padding: "0.5rem 1rem",
                background:
                  activeCategory === category
                    ? theme === "dark"
                      ? "var(--bs-primary)"
                      : "var(--bs-primary)"
                    : "transparent",
                color:
                  activeCategory === category
                    ? "#fff"
                    : theme === "dark"
                    ? "#fff"
                    : "var(--bs-primary)",
                border: `1px solid ${
                  theme === "dark" ? "#fff" : "var(--bs-primary)"
                }`,
                opacity: theme === "dark" ? 0.9 : 1,
              }}
            >
              {category}
            </Button>
          ))}
        </div>

        <Row className="g-4">
          {visibleCertificates.map((cert) => (
            <Col key={cert.id} xs={12} sm={6} lg={4}>
              <Card
                className="h-100 certificate-card"
                style={{
                  background:
                    theme === "dark"
                      ? "rgba(255,255,255,0.05)"
                      : "rgba(0,0,0,0.03)",
                  backdropFilter: "blur(10px)",
                  border:
                    theme === "dark"
                      ? "1px solid rgba(255,255,255,0.1)"
                      : "1px solid rgba(0,0,0,0.1)",
                  transition: "transform 0.3s ease",
                }}
              >
                <div className="p-3" style={{ background: "transparent" }}>
                  <Card.Img
                    variant="top"
                    src={cert.image}
                    alt={cert.title}
                    className="certificate-image"
                    style={{ borderRadius: "8px" }}
                  />
                </div>

                <Card.Body>
                  <Card.Title
                    style={{
                      color: theme === "dark" ? "#ffffff" : "#212529",
                      fontSize: "1.25rem",
                      marginBottom: "1rem",
                    }}
                  >
                    {cert.title}
                  </Card.Title>
                  <div
                    style={{
                      color:
                        theme === "dark"
                          ? "rgba(255,255,255,0.7)"
                          : "rgba(0,0,0,0.7)",
                      marginBottom: "0.5rem",
                      fontSize: "0.9rem",
                    }}
                  >
                    <div className="mb-1">{cert.issuer}</div>
                    <div>{cert.date}</div>
                  </div>

                  <div className="mt-3">
                    {cert.skills.map((skill, index) => (
                      <Badge
                        key={index}
                        bg={cert.bgColor}
                        className="me-2 mb-2"
                        style={{ borderRadius: "4px" }}
                      >
                        {skill}
                      </Badge>
                    ))}
                  </div>
                </Card.Body>

                <Card.Footer
                  style={{
                    background: "transparent",
                    border: "none",
                    padding: "1rem",
                  }}
                >
                  <Button
                    size="sm"
                    variant={cert.bgColor}
                    onClick={() => window.open(cert.url, "_blank")}
                    style={{
                      width: "100%",
                      borderRadius: 0,
                      transition: "all 0.3s ease",
                      textTransform: "uppercase",
                      letterSpacing: "1px",
                      color: "#fff",
                      border: "none",
                    }}
                  >
                    View Certificate
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Show More/Less Buttons */}
        <div className="text-center mt-5">
          {!isShowingAll && (
            <Button
              variant={theme === "dark" ? "outline-light" : "outline-dark"}
              size="lg"
              onClick={handleShowMore}
              style={{
                borderRadius: 0,
                transition: "all 0.3s ease",
                textTransform: "uppercase",
                letterSpacing: "1px",
                padding: "0.8rem 2rem",
                marginRight: "1rem",
              }}
            >
              Show More Certificates
            </Button>
          )}
          {isShowingMore && (
            <Button
              variant={theme === "dark" ? "outline-light" : "outline-dark"}
              size="lg"
              onClick={handleShowLess}
              style={{
                borderRadius: 0,
                transition: "all 0.3s ease",
                textTransform: "uppercase",
                letterSpacing: "1px",
                padding: "0.8rem 2rem",
              }}
            >
              Show Less Certificates
            </Button>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Certificates;
