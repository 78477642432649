import docker from "../Image/Certificat/docker.png";
import OWASP from "../Image/Certificat/OWASP.jpg";
import EnglishSpocken from "../Image/Certificat/britishEtiquette.jpg";
import penseCritique from "../Image/Certificat/PenseeCritiquePriseDecision.jpg";

export const certificates = [
  {
    id: 1,
    title: "OWASP Top 10 2021 Web Application Security Made Simple",
    issuer: "freeCodeCamp",
    date: "January 2024",
    image: OWASP,
    skills: ["JavaScript", "ES6+", "Algorithms", "Data Structures"],
    bgColor: "success",
    url: "https://drive.google.com/file/d/15K6WoRrClUnXzkmBIi8vsyPYssuxJ6FS/view?usp=sharing",
    category: "Security",
  },
  {
    id: 2,
    title: "Pensée Critique et Prise de Décision",
    issuer: "Udemy",
    date: "February 2025",
    image: penseCritique,
    skills: [
      "Gestion",
      "leadership",
      "Sciences humaines et sociales",
      "Psychologie cognitive",
    ],
    bgColor: "info",
    url: "https://drive.google.com/file/d/1OFvmWOBuPAz8HTI0UavDD97dV-54KboY/view?usp=sharing",
    category: "Développement personnel et professionnel",
  },
  {
    id: 3,
    title: "The Absolute Beginner's Guide to Docker",
    issuer: "PLURALSIGHT",
    date: "Feb 22, 2024",
    image: docker,
    skills: ["Docker"],
    bgColor: "primary",
    url: "https://drive.google.com/file/d/15K6WoRrClUnXzkmBIi8vsyPYssuxJ6FS/view?usp=sharing",
    category: "Cloud",
  },
  {
    id: 4,
    title: "Understand British spoken English: A listening course",
    issuer: "Udemy",
    date: "December 2023",
    image: EnglishSpocken,
    skills: ["English", "Listening", "Speaking", "Language"],
    bgColor: "danger",
    url: "https://drive.google.com/file/d/15K6WoRrClUnXzkmBIi8vsyPYssuxJ6FS/view?usp=sharing",
    category: "Languages & Communication",
  },
];
