export const experienceData = [
  {
    date: "02/2021",
    company: "Intelcia IT Solution",
    role: "Full-stack Developer",
  },
  {
    date: "09/2020 - 01/2021",
    company: "SAGlobal",
    role: "Developer CRM Dynamics 365",
  },
  {
    date: "03/2020 - 08/2020",
    company: "SAGlobal",
    role: "Internship - Development the Transport Management System under Dynamics 365",
  },
  {
    date: "07/2020 - 09/2020",
    company: "Pexiluis Africa",
    role: "Internship - Development of a decision information system for Pexiluis Africa management",
  },
];
