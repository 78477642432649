import React, { useEffect, useRef, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import * as THREE from "three";
import { TypeAnimation } from "react-type-animation";
import { ThemeContext } from "../../context/ThemeContext";
import { motion } from "framer-motion";

const Profile = () => {
  const { theme } = useContext(ThemeContext);
  const mountRef = useRef(null);
  const stateRef = useRef({
    scene: null,
    camera: null,
    renderer: null,
    particles: null,
    mouse: new THREE.Vector2(),
    frameId: null,
    aspectRatio: window.innerWidth / window.innerHeight,
  });

  useEffect(() => {
    const { current: s } = stateRef;

    // Shader materials for better performance
    const vertexShader = `
      attribute vec3 velocity;
      varying vec3 vPosition;
      uniform vec2 mouse;
      uniform float time;
      
      void main() {
        vPosition = position + velocity * time;
        
        // Enhanced mouse interaction
        float distanceToMouse = length(vPosition.xy - mouse * 30.0); // Increased interaction range
        if(distanceToMouse < 8.0) { // Increased interaction radius
          vPosition.xy += (mouse * 3.0 - vPosition.xy) * 0.05; // Stronger mouse influence
        }
        
        // Boundary check
        vec3 bounds = vec3(25.0);
        vPosition = mod(vPosition + bounds, bounds * 2.0) - bounds;
        
        gl_Position = projectionMatrix * modelViewMatrix * vec4(vPosition, 1.0);
        gl_PointSize = 3.0; // Increased point size
      }
    `;

    const fragmentShader = `
      varying vec3 vPosition;
      
      void main() {
        float intensity = 1.0 - length(gl_PointCoord - 0.5) * 2.0;
        gl_FragColor = vec4(vec3(intensity), intensity * 0.8);
      }
    `;

    // Scene setup
    const initScene = () => {
      s.scene = new THREE.Scene();
      s.camera = new THREE.PerspectiveCamera(75, s.aspectRatio, 0.1, 1000);
      s.camera.position.z = 30;

      s.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      s.renderer.setSize(window.innerWidth, window.innerHeight);
      s.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
      mountRef.current.appendChild(s.renderer.domElement);
    };

    // Particle system
    const initParticles = () => {
      const particleCount = 1500;
      const geometry = new THREE.BufferGeometry();
      const positions = new Float32Array(particleCount * 3);
      const velocities = new Float32Array(particleCount * 3);

      for (let i = 0; i < particleCount * 3; i += 3) {
        positions[i] = (Math.random() - 0.5) * 50;
        positions[i + 1] = (Math.random() - 0.5) * 50;
        positions[i + 2] = (Math.random() - 0.5) * 50;

        velocities[i] = (Math.random() - 0.5) * 0.05;
        velocities[i + 1] = (Math.random() - 0.5) * 0.05;
        velocities[i + 2] = (Math.random() - 0.5) * 0.05;
      }

      geometry.setAttribute(
        "position",
        new THREE.BufferAttribute(positions, 3)
      );
      geometry.setAttribute(
        "velocity",
        new THREE.BufferAttribute(velocities, 3)
      );

      // Add size variation attribute
      const sizes = new Float32Array(particleCount);
      for (let i = 0; i < particleCount; i++) {
        sizes[i] = 0.5 + Math.random() * 2.5; // Random sizes between 0.5 and 3.0
      }
      geometry.setAttribute("size", new THREE.BufferAttribute(sizes, 1));

      const material = new THREE.ShaderMaterial({
        vertexShader,
        fragmentShader,
        transparent: true,
        uniforms: {
          time: { value: 0 },
          mouse: { value: new THREE.Vector2() },
        },
      });

      s.particles = new THREE.Points(geometry, material);
      s.scene.add(s.particles);
    };

    // Animation loop
    const animate = (time = 0) => {
      s.frameId = requestAnimationFrame(animate);

      if (s.particles) {
        s.particles.material.uniforms.time.value = time * 0.001;
        // Enhanced mouse tracking with smoother interpolation
        s.particles.material.uniforms.mouse.value.lerp(s.mouse, 0.1);
        s.particles.rotation.y += 0.001;
      }

      s.renderer.render(s.scene, s.camera);
    };

    // Event handlers
    const handleResize = () => {
      s.aspectRatio = window.innerWidth / window.innerHeight;
      s.camera.aspect = s.aspectRatio;
      s.camera.updateProjectionMatrix();
      s.renderer.setSize(window.innerWidth, window.innerHeight);
    };

    const handleMouseMove = (e) => {
      s.mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
      s.mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;
    };

    // Initialization
    initScene();
    initParticles();
    animate();

    // Optimized event listeners
    window.addEventListener("resize", handleResize, { passive: true });
    window.addEventListener("mousemove", handleMouseMove, { passive: true });

    // Cleanup
    return () => {
      const mountNode = mountRef.current;

      window.removeEventListener("resize", handleResize);
      window.removeEventListener("mousemove", handleMouseMove);
      cancelAnimationFrame(s.frameId);

      if (mountNode && s.renderer) {
        mountNode.removeChild(s.renderer.domElement);
      }

      [s.scene, s.camera, s.renderer, s.particles].forEach((item) => {
        if (item) {
          item.dispose?.();
          if (item.geometry) item.geometry.dispose();
          if (item.material) item.material.dispose();
        }
      });
    };
  }, []);

  return (
    <div
      className="position-relative"
      style={{
        height: "100vh",
        background:
          theme === "dark"
            ? "linear-gradient(to bottom, #0a0a0a, #1a1a1a)"
            : "linear-gradient(to bottom, #f8f9fa, #e9ecef)",
      }}
    >
      <div ref={mountRef} className="position-absolute w-100 h-100" />

      <Container fluid className="h-100">
        <Row
          className="h-100 align-items-center position-relative"
          style={{ zIndex: 1 }}
        >
          <Col
            md={8}
            className="mx-auto text-center"
            style={{ color: theme === "dark" ? "#ffffff" : "#212529" }}
            component={motion.div}
            viewport={{ once: true, margin: "0px 0px -30% 0px" }}
            transition={{ duration: 0.8 }}
          >
            <h2
              className="display-3 fw-bold mb-4"
              style={{ letterSpacing: "2px" }}
            >
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, margin: "0px 0px -15% 0px" }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                I am{" "}<br/>
                <TypeAnimation
                  sequence={[
                    "SARA SENNOUNI",
                    1500,
                    "FULL STACK DEVELOPER",
                    1500,
                  ]}
                  speed={40}
                  style={{ color: "#007bff", textShadow: "2px 2px #bebebe" }}
                  wrapper="span"
                  repeat={Infinity}
                />
              </motion.div>
            </h2>
            <motion.div
              className="lead mb-5"
              style={{ fontSize: "1.5rem" }}
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true, margin: "0px 0px -10% 0px" }}
              transition={{ duration: 0.6, delay: 0.4 }}
            >
              I am a responsible and hard-working IT professional with
              experience across multiple projects. My adaptability and
              team-oriented approach enable me to excel in collaborative
              environments while maintaining the initiative to work
              independently under pressure and meet tight deadlines.
            </motion.div>
            <motion.div
              className="d-flex justify-content-center gap-3"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, margin: "0px 0px -40% 0px" }}
              variants={{
                visible: {
                  transition: {
                    staggerChildren: 0.2,
                    delayChildren: 1.2,
                  },
                },
                hidden: {
                  transition: {
                    staggerChildren: 0.1,
                    staggerDirection: -1,
                  },
                },
              }}
            >
              <Button
                variant={theme === "dark" ? "outline-light" : "outline-dark"}
                size="lg"
                className="px-5 py-3"
                href="https://drive.google.com/file/d/1EezWAinaokrZhOF-yMitb2chqUBJay6M/view?usp=share_link"
                style={buttonStyle}
              >
                View Resume
              </Button>
              <Button
                variant={theme === "dark" ? "light" : "primary"}
                size="lg"
                className="px-5 py-3"
                style={buttonStyle}
              >
                Contact Me
              </Button>
            </motion.div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const buttonStyle = {
  borderRadius: 0,
  transition: "all 0.3s ease",
  textTransform: "uppercase",
  letterSpacing: "1px",
  minWidth: "200px",
};

export default Profile;
