export const lightTheme = {
  body: "#ffffff",
  text: "#000000",
  textSecondary: "rgba(0, 0, 0, 0.7)",
  background: "linear-gradient(to bottom, #ffffff, #f5f5f5)",
  cardBackground: "rgba(0, 0, 0, 0.02)",
  border: "rgba(0, 0, 0, 0.1)",
  buttonText: "#000000",
  buttonBorder: "#000000",
  navBackground: "rgba(255, 255, 255, 0.8)",
  cardHover: "rgba(0, 0, 0, 0.05)",
  timeline: {
    dot: "#0056b3",
    connector: "rgba(0, 0, 0, 0.2)",
    content: "rgba(0, 0, 0, 0.02)",
  },
  toggleBorder: "#FFF",
  blocs: "#F8F9FA",
  nav: "light",
  variant: "light",
  card: "#FFF",
  flowText: "#212529bf",
};
export const darkTheme = {
  body: "#1a1a1a",
  text: "#ffffff",
  textSecondary: "rgba(255, 255, 255, 0.7)",
  background: "linear-gradient(to bottom, #1a1a1a, #0a0a0a)",
  cardBackground: "rgba(255, 255, 255, 0.05)",
  border: "rgba(255, 255, 255, 0.1)",
  buttonText: "#ffffff",
  buttonBorder: "#ffffff",
  navBackground: "rgba(26, 26, 26, 0.8)",
  cardHover: "rgba(255, 255, 255, 0.1)",
  timeline: {
    dot: "#007bff",
    connector: "rgba(255, 255, 255, 0.2)",
    content: "rgba(255, 255, 255, 0.05)",
  },
  toggleBorder: "#6B8096",
  blocs: "#686669",
  nav: "dark",
  variant: "dark",
  card: "#929292",
  flowText: "rgba(168,167,167,0.75)",
};
