import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text};
    transition: all 0.3s ease;
  }
  .blocs {
    background: ${({ theme }) => theme.blocs};
  }
  Navbar {
    bg: ${({ theme }) => theme.nav};
  }
  Paper {
    backgroundColor : ${({ theme }) => theme.blocs} ;
  }
  .Card {
    background-color: ${({ theme }) => theme.card};
  }
  .text-principal {
    color: ${({ theme }) => theme.text};
  }
  .text-flow {
    color: ${({ theme }) => theme.flowText};
  }
  .card {
    background: ${({ theme }) => theme.cardBackground};
    border: 1px solid ${({ theme }) => theme.border};
    backdrop-filter: blur(10px);
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      background: ${({ theme }) => theme.cardHover};
    }
  }
  .nav-link {
    color: ${({ theme }) => theme.text} !important;
    transition: all 0.3s ease;

    &:hover {
      color: ${({ theme }) => theme.textSecondary} !important;
    }
  }
  .btn-outline-primary {
    color: ${({ theme }) => theme.buttonText};
    border-color: ${({ theme }) => theme.buttonBorder};
    
    &:hover {
      background: ${({ theme }) => theme.buttonBorder};
      color: ${({ theme }) => theme.body};
    }
  }
  .timeline-dot {
    background-color: ${({ theme }) => theme.timeline.dot} !important;
  }
  .timeline-connector {
    background-color: ${({ theme }) => theme.timeline.connector} !important;
  }
  .timeline-content {
    background: ${({ theme }) => theme.timeline.content} !important;
  }
  .form-control {
    background: ${({ theme }) => theme.cardBackground};
    border: 1px solid ${({ theme }) => theme.border};
    color: ${({ theme }) => theme.text};

    &::placeholder {
      color: ${({ theme }) => theme.textSecondary};
    }

    &:focus {
      background: ${({ theme }) => theme.cardBackground};
      border-color: ${({ theme }) => theme.buttonBorder};
      color: ${({ theme }) => theme.text};
      box-shadow: none;
    }
  }
  .social-icons a {
    color: ${({ theme }) => theme.text};
    transition: all 0.3s ease;

    &:hover {
      color: ${({ theme }) => theme.textSecondary};
    }
  }
  .section-title {
    color: ${({ theme }) => theme.text};
    border-bottom: 2px solid ${({ theme }) => theme.border};
  }
  .glass-card {
    background: ${({ theme }) => theme.cardBackground};
    backdrop-filter: blur(10px);
    border: 1px solid ${({ theme }) => theme.border};
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      background: ${({ theme }) => theme.cardHover};
    }
  }
  `;
