import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import logoWhite from "./Image/SS.png";
import logoDark from "./Image/SSWhite.png";
import { Container, Nav, Navbar } from "react-bootstrap";
import Profile from "./Pages/Screens/Profile";
import Education from "./Pages/Screens/Education";
import Experience from "./Pages/Screens/Experience";
import Skills from "./Pages/Screens/Skills";
import Project from "./Pages/Screens/Project";
import Contact from "./Pages/Screens/Contact";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { GlobalStyle } from "./Pages/Screens/GlobalStyle";
import { lightTheme, darkTheme } from "./Pages/Screens/theme";
import AnimatedCursor from "react-animated-cursor";
import Certificates from "./Pages/Screens/Certificates";
import Footer from "./Pages/Component/Footer";
import { ThemeProvider } from "./context/ThemeContext";
import { useContext } from "react";
import { ThemeContext } from "./context/ThemeContext";

function AppContent() {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const logo = theme === "light" ? logoWhite : logoDark;

  const LightIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-brightness-up"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="#ffec00"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <circle cx="12" cy="12" r="3" />
      <line x1="12" y1="5" x2="12" y2="3" />
      <line x1="17" y1="7" x2="18.4" y2="5.6" />
      <line x1="19" y1="12" x2="21" y2="12" />
      <line x1="17" y1="17" x2="18.4" y2="18.4" />
      <line x1="12" y1="19" x2="12" y2="21" />
      <line x1="7" y1="17" x2="5.6" y2="18.4" />
      <line x1="6" y1="12" x2="4" y2="12" />
      <line x1="7" y1="7" x2="5.6" y2="5.6" />
    </svg>
  );

  const DarkIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-moon"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke={theme === "dark" ? "#ffffff" : "#000000"}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z" />
    </svg>
  );

  return (
    <StyledThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <>
        <GlobalStyle />
        <AnimatedCursor
          innerSize={7}
          outerSize={20}
          color="17, 163, 231"
          outerAlpha={0.2}
          innerScale={0.7}
          outerScale={4}
          innerStyle={{
            zIndex: 9999,
            position: "fixed",
            pointerEvents: "none",
          }}
          outerStyle={{
            zIndex: 9999,
            position: "fixed",
            pointerEvents: "none",
          }}
        />

        <div className="App">
          <Navbar
            variant={theme}
            expand="lg"
            className="shadow-lg position-fixed w-100"
            style={{
              background:
                theme === "dark"
                  ? "rgba(26, 26, 26, 0.8)"
                  : "rgba(255, 255, 255, 0.8)",
              backdropFilter: "blur(10px)",
              zIndex: 1000,
              borderBottom:
                theme === "dark"
                  ? "1px solid rgba(255, 255, 255, 0.1)"
                  : "1px solid rgba(0, 0, 0, 0.1)",
            }}
          >
            <Container>
              <Navbar.Brand href="#home">
                <img
                  alt="sennouni sara"
                  className="image"
                  src={logo}
                  width={60}
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto"></Nav>
                <Nav className="mr-sm-4">
                  <Nav.Link
                    href="#profile"
                    style={{ color: theme === "dark" ? "#ffffff" : "#000000" }}
                  >
                    Profile
                  </Nav.Link>
                  <Nav.Link
                    href="#education"
                    style={{ color: theme === "dark" ? "#ffffff" : "#000000" }}
                  >
                    Education
                  </Nav.Link>
                  <Nav.Link
                    href="#experience"
                    style={{ color: theme === "dark" ? "#ffffff" : "#000000" }}
                  >
                    Experience
                  </Nav.Link>
                  <Nav.Link
                    href="#contact"
                    style={{ color: theme === "dark" ? "#ffffff" : "#000000" }}
                  >
                    Contact
                  </Nav.Link>
                  <Nav.Link
                    onClick={toggleTheme}
                    style={{ color: theme === "dark" ? "#ffffff" : "#000000" }}
                  >
                    {theme === "dark" ? <LightIcon /> : <DarkIcon />}
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <Profile />
          <Skills />
          <Experience />
          <Project />
          <Certificates />
          <Education />
          <Contact />
          <Footer />
        </div>
      </>
    </StyledThemeProvider>
  );
}

function App() {
  return (
    <ThemeProvider>
      <AppContent />
    </ThemeProvider>
  );
}

export default App;
