import React, { useContext, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { ThemeContext } from "../../context/ThemeContext";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const { theme } = useContext(ThemeContext);
  const [formData, setFormData] = useState({
    nom: "",
    email: "",
    sujet: "",
    message: "",
  });
  const [validated, setValidated] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const serviceId = "service_0xxfx1l";
  const templateId = "template_fwouojc";
  const publicKey = "t6dQ0YRTm0VZYxtvS";

  // Object of dynamics template params
  const templateParams = {
    from_name: formData.nom,
    from_email: formData.email,
    subject: formData.sujet,
    message: formData.message,
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true); // Show validation state

    if (form.checkValidity() === false) {
      event.stopPropagation();
      return; // Stop here if invalid
    }

    // Only proceed if form is valid
    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then(() => {
        setValidated(false); // Reset validation state
        setFormData({
          // Reset form
          nom: "",
          email: "",
          sujet: "",
          message: "",
        });
      })
      .catch((error) => {
        console.error("Failed:", error);
      });
  };

  const contactInfo = [
    {
      icon: <FaPhone size={24} />,
      title: "Contact on phone",
      text: "+212-698-662949",
      link: "tel:+212698662949",
      bgColor: "rgba(135, 206, 250, 0.2)",
    },
    {
      icon: <FaEnvelope size={24} />,
      title: "Contact on mail",
      text: ["sarasennouni@outlook.com", "sara.sennouni1214@gmail.com"],
      link: "mailto:sara.sennouni1214@gmail.com",
      bgColor: "rgba(135, 206, 250, 0.2)",
    },
    {
      icon: <FaMapMarkerAlt size={24} />,
      title: "Contact address",
      text: "Boulevard Laymoune - Morocco, Casablanca",
      link: "https://maps.google.com/?q=Boulevard+Laymoune+Casablanca+Morocco",
      bgColor: "rgba(135, 206, 250, 0.2)",
    },
  ];

  const ContactCard = ({ info }) => (
    <a
      href={info.link}
      className="text-decoration-none"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div
        style={{
          background: theme === "dark" ? "rgba(255,255,255,0.05)" : "#FFFFFF",
          border:
            theme === "dark"
              ? "1px solid rgba(255,255,255,0.1)"
              : "1px solid #E0E0E0",
          borderRadius: "12px",
          padding: "20px",
          marginBottom: "20px",
          display: "flex",
          alignItems: "flex-start",
          gap: "15px",
          transition: "transform 0.3s ease, background 0.3s ease",
          cursor: "pointer",
          backdropFilter: "blur(10px)",
        }}
        onMouseOver={(e) =>
          (e.currentTarget.style.transform = "translateY(-5px)")
        }
        onMouseOut={(e) => (e.currentTarget.style.transform = "translateY(0)")}
      >
        <div
          style={{
            background:
              theme === "dark"
                ? "rgba(135, 206, 250, 0.15)"
                : "rgba(135, 206, 250, 0.2)",
            borderRadius: "50%",
            width: "48px",
            height: "48px",
            minWidth: "48px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: theme === "dark" ? "#90CAF9" : "#007bff",
            marginTop: "4px",
          }}
        >
          {info.icon}
        </div>
        <div style={{ textAlign: "left", flex: 1 }}>
          <h6
            style={{
              margin: "0 0 4px 0",
              color: theme === "dark" ? "#FFFFFF" : "#000000",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            {info.title}
          </h6>
          {Array.isArray(info.text) ? (
            info.text.map((text, index) => (
              <p
                key={index}
                style={{
                  margin: "0",
                  color: theme === "dark" ? "rgba(255,255,255,0.7)" : "#666666",
                  fontSize: "14px",
                  fontStyle: "italic",
                  lineHeight: "1.5",
                }}
              >
                {text}
              </p>
            ))
          ) : (
            <p
              style={{
                margin: "0",
                color: theme === "dark" ? "rgba(255,255,255,0.7)" : "#666666",
                fontSize: "14px",
                fontStyle: "italic",
                lineHeight: "1.5",
              }}
            >
              {info.text}
            </p>
          )}
        </div>
      </div>
    </a>
  );

  return (
    <div
      id="contact"
      style={{
        background:
          theme === "dark"
            ? "linear-gradient(to bottom, #1a1a1a, #0a0a0a)"
            : "linear-gradient(to bottom, #f8f9fa, #e9ecef)",
        padding: "5vh 5vw 2vh",
      }}
    >
      <Container>
        <div className="text-center mb-5">
          <h2
            className="display-4 mb-3"
            style={{
              color: theme === "dark" ? "#ffffff" : "#212529",
              letterSpacing: "2px",
              fontWeight: "bold",
            }}
          >
            Contact Me
          </h2>
          <p className="lead" style={{ fontSize: "1.2rem" }}>
            Let's connect and discuss how we can work together
          </p>
        </div>

        <Row className="justify-content-between">
          {/* Contact Info Cards */}
          <Col md={5}>
            <div style={{ maxWidth: "450px", margin: "0 auto" }}>
              {contactInfo.map((info, index) => (
                <ContactCard key={index} info={info} />
              ))}
            </div>
          </Col>

          {/* Contact Form */}
          <Col md={6}>
            <div
              style={{
                background:
                  theme === "dark"
                    ? "rgba(255,255,255,0.05)"
                    : "rgba(135, 206, 250, 0.1)",
                backdropFilter: "blur(10px)",
                border:
                  theme === "dark"
                    ? "1px solid rgba(255,255,255,0.1)"
                    : "1px solid rgba(0,0,0,0.1)",
                borderRadius: "15px",
                padding: "30px",
              }}
            >
              <h4
                className="mb-4"
                style={{
                  color: theme === "dark" ? "#ffffff" : "#212529",
                }}
              >
                Send me a message
              </h4>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Your Name"
                    name="nom"
                    value={formData.nom}
                    onChange={handleChange}
                    style={{
                      background:
                        theme === "dark" ? "rgba(255,255,255,0.1)" : "#fff",
                      border:
                        theme === "dark"
                          ? "1px solid rgba(255,255,255,0.2)"
                          : null,
                      color: theme === "dark" ? "#fff" : "#000",
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Control
                    required
                    type="email"
                    placeholder="Your Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    style={{
                      background:
                        theme === "dark" ? "rgba(255,255,255,0.1)" : "#fff",
                      border:
                        theme === "dark"
                          ? "1px solid rgba(255,255,255,0.2)"
                          : null,
                      color: theme === "dark" ? "#fff" : "#000",
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Subject"
                    name="sujet"
                    value={formData.sujet}
                    onChange={handleChange}
                    style={{
                      background:
                        theme === "dark" ? "rgba(255,255,255,0.1)" : "#fff",
                      border:
                        theme === "dark"
                          ? "1px solid rgba(255,255,255,0.2)"
                          : null,
                      color: theme === "dark" ? "#fff" : "#000",
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Control
                    required
                    as="textarea"
                    rows={4}
                    placeholder="Your Message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    style={{
                      background:
                        theme === "dark" ? "rgba(255,255,255,0.1)" : "#fff",
                      border:
                        theme === "dark"
                          ? "1px solid rgba(255,255,255,0.2)"
                          : null,
                      color: theme === "dark" ? "#fff" : "#000",
                    }}
                  />
                </Form.Group>

                <Button
                  type="submit"
                  variant={theme === "dark" ? "light" : "primary"}
                  size="lg"
                  className="w-100"
                  style={{
                    borderRadius: "8px",
                    textTransform: "uppercase",
                    letterSpacing: "1px",
                  }}
                >
                  Send Message
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
