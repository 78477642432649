import * as React from "react";
import { Container, Image, Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { skillsData } from "../../data/Skills";
import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";

const Skills = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <div
      id="skills"
      className="position-relative"
      style={{
        minHeight: "100vh",
        background:
          theme === "dark"
            ? "linear-gradient(to bottom, #1a1a1a, #0a0a0a)"
            : "linear-gradient(to bottom, #f8f9fa, #e9ecef)",
        padding: "80px 0",
      }}
    >
      <Container>
        <div className="text-center mb-5">
          <h2
            className="display-4 mb-3"
            style={{
              color: theme === "dark" ? "#ffffff" : "#212529",
              letterSpacing: "2px",
              fontWeight: "bold",
            }}
          >
            Skills
          </h2>
          <p
            className="lead"
            style={{
              fontSize: "1.2rem",
            }}
          >
            Showcasing my commitment to continuous learning and professional
            development
          </p>
        </div>
        <Row className="g-4">
          {skillsData.map((category, index) => (
            <Col key={index} lg={4} md={6} className="text-center">
              <div
                style={{
                  background:
                    theme === "dark"
                      ? "rgba(255,255,255,0.05)"
                      : "rgba(0,0,0,0.05)",
                  borderRadius: "15px",
                  padding: "30px 20px",
                  transition: "transform 0.3s ease",
                  cursor: "pointer",
                  backdropFilter: "blur(10px)",
                  border:
                    theme === "dark"
                      ? "1px solid rgba(255,255,255,0.1)"
                      : "1px solid rgba(0,0,0,0.1)",
                }}
                className="h-100"
                onMouseOver={(e) =>
                  (e.currentTarget.style.transform = "translateY(-5px)")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.transform = "translateY(0)")
                }
              >
                <Image
                  src={category.imageSrc}
                  style={{
                    paddingBlock: "20px",
                    maxWidth: "80px",
                    height: "auto",
                    filter: "brightness(0.9)",
                  }}
                />
                <h6
                  style={{
                    color: theme === "dark" ? "#ffffff" : "#212529",
                    fontSize: "1.2rem",
                    marginTop: "15px",
                    fontWeight: "600",
                  }}
                >
                  {category.category}
                </h6>
                <p
                  style={{
                    color:
                      theme === "dark" ? "rgba(255,255,255,0.7)" : "#212529",
                    marginBottom: 0,
                  }}
                >
                  {category.technologies.join(", ")}
                </p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Skills;
