import React, { useState, useContext } from "react";
import { Container, Row, Col, Card, Button, Badge } from "react-bootstrap";
import { projects } from "../../data/Projects";
import "./../Screens/style.css";
import { ThemeContext } from "../../context/ThemeContext";

const Project = () => {
  const { theme } = useContext(ThemeContext);
  const categories = ["All", "Full Stack", "Frontend", "Backend"];
  const [activeCategory, setActiveCategory] = useState("All");

  // Get initial visible items based on screen size
  const getInitialVisibleItems = () => {
    if (window.innerWidth >= 992) return 3; // lg and up: 3 items
    return 2; // md and sm: 2 items
  };

  const [visibleProjects, setVisibleProjects] = useState(
    getInitialVisibleItems()
  );

  // Filter projects based on category
  const filteredProjects =
    activeCategory === "All"
      ? projects
      : projects.filter((project) => project.category === activeCategory);

  // Get currently visible projects
  const currentlyVisible = filteredProjects.slice(0, visibleProjects);

  // Handle Show More click
  const handleShowMore = () => {
    const increment = window.innerWidth >= 992 ? 3 : 2;
    setVisibleProjects((prev) => prev + increment);
  };

  // Handle Show Less click
  const handleShowLess = () => {
    setVisibleProjects(getInitialVisibleItems());
    document.getElementById("project").scrollIntoView({ behavior: "smooth" });
  };

  // Reset visible projects when category changes
  const handleCategoryChange = (category) => {
    setActiveCategory(category);
    setVisibleProjects(getInitialVisibleItems());
  };

  return (
    <div
      id="project"
      className="position-relative"
      style={{
        minHeight: "100vh",
        background:
          theme === "dark"
            ? "linear-gradient(to bottom, #1a1a1a, #0a0a0a)"
            : "linear-gradient(to bottom, #f8f9fa, #e9ecef)",
        padding: "80px 0",
      }}
    >
      <Container>
        {/* Header Section */}
        <div className="text-center mb-5">
          <h2
            className="display-4 mb-3"
            style={{
              color: theme === "dark" ? "#ffffff" : "#212529",
              letterSpacing: "2px",
              fontWeight: "bold",
            }}
          >
            My Projects
          </h2>
          <p
            className="lead"
            style={{
              fontSize: "1.2rem",
            }}
          >
            Explore some of my recent work and personal projects
          </p>
        </div>

        {/* Category Filters */}
        <div className="text-center mb-4">
          {categories.map((category) => (
            <Button
              key={category}
              variant="outline-primary"
              className="mx-2 mb-2"
              onClick={() => handleCategoryChange(category)}
              style={{
                transition: "all 0.3s ease",
                borderRadius: 0,
                textTransform: "uppercase",
                letterSpacing: "1px",
                background:
                  activeCategory === category
                    ? theme === "dark"
                      ? "#ffffff"
                      : "#000000"
                    : "transparent",
                color:
                  activeCategory === category
                    ? theme === "dark"
                      ? "#1a1a1a"
                      : "#ffffff"
                    : theme === "dark"
                    ? "#ffffff"
                    : "#000000",
                borderWidth: "2px",
              }}
            >
              {category}
            </Button>
          ))}
        </div>

        {/* Projects Grid - Updated Col breakpoints */}
        <Row className="g-4">
          {currentlyVisible.map((project) => (
            <Col key={project.id} xs={12} sm={6} lg={4}>
              <Card
                className="h-100 project-card"
                style={{
                  background:
                    theme === "dark"
                      ? "rgba(255,255,255,0.05)"
                      : "rgba(0,0,0,0.03)",
                  backdropFilter: "blur(10px)",
                  border:
                    theme === "dark"
                      ? "1px solid rgba(255,255,255,0.1)"
                      : "1px solid rgba(0,0,0,0.1)",
                  transition: "transform 0.3s ease",
                }}
              >
                <div className="project-image-wrapper">
                  <Card.Img
                    variant="top"
                    src={project.image}
                    alt={project.title}
                    className="project-image"
                  />
                  <div className="project-overlay">
                    <Button
                      variant={
                        theme === "dark" ? "outline-light" : "outline-dark"
                      }
                      size="sm"
                      href={project.liveDemo}
                      target="_blank"
                      className="me-2"
                      style={{ borderRadius: 0 }}
                    >
                      Live Demo
                    </Button>
                    <Button
                      variant={
                        theme === "dark" ? "outline-light" : "outline-dark"
                      }
                      size="sm"
                      href={project.sourceCode}
                      target="_blank"
                      style={{ borderRadius: 0 }}
                    >
                      Source Code
                    </Button>
                  </div>
                </div>

                <Card.Body>
                  <Card.Title
                    style={{ color: theme === "dark" ? "#ffffff" : "#212529" }}
                  >
                    {project.title}
                  </Card.Title>
                  <Card.Text
                    style={{
                      color:
                        theme === "dark" ? "rgba(255,255,255,0.7)" : "#212529",
                    }}
                  >
                    {project.description}
                  </Card.Text>
                  <div className="mt-3">
                    {project.technologies.map((tech, index) => (
                      <Badge
                        key={index}
                        bg={"primary"}
                        className="me-2 mb-2"
                        style={{ borderRadius: "4px" }}
                      >
                        {tech}
                      </Badge>
                    ))}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Show More/Less Button */}
        <div className="text-center mt-5">
          {visibleProjects < filteredProjects.length ? (
            <Button
              variant={theme === "dark" ? "outline-light" : "outline-dark"}
              size="lg"
              onClick={handleShowMore}
              style={{
                borderRadius: 0,
                transition: "all 0.3s ease",
                textTransform: "uppercase",
                letterSpacing: "1px",
                padding: "0.8rem 2rem",
              }}
            >
              Show More Projects
            </Button>
          ) : (
            visibleProjects > getInitialVisibleItems() && (
              <Button
                variant={theme === "dark" ? "outline-light" : "outline-dark"}
                size="lg"
                onClick={handleShowLess}
                style={{
                  borderRadius: 0,
                  transition: "all 0.3s ease",
                  textTransform: "uppercase",
                  letterSpacing: "1px",
                  padding: "0.8rem 2rem",
                }}
              >
                Show Less Projects
              </Button>
            )
          )}
        </div>
      </Container>
    </div>
  );
};

export default Project;
