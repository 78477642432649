import React, { useContext, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import logoWhite from "../../Image/SS.png";
import logoDark from "../../Image/SSWhite.png";
import * as THREE from "three";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import CodeOutlinedIcon from "@mui/icons-material/CodeOutlined";
import GitHubIcon from "@mui/icons-material/GitHub";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { ThemeContext } from "../../context/ThemeContext";

import "./../Screens/style.css";

const Footer = () => {
  const { theme } = useContext(ThemeContext);
  const mountRef = useRef(null);
  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const rendererRef = useRef(null);
  const particlesRef = useRef(null);
  const frameIdRef = useRef(null);

  useEffect(() => {
    const initThree = () => {
      // Scene setup
      sceneRef.current = new THREE.Scene();

      // Camera setup
      const aspectRatio = window.innerWidth / window.innerHeight;
      cameraRef.current = new THREE.PerspectiveCamera(
        75,
        aspectRatio,
        0.1,
        1000
      );
      cameraRef.current.position.z = 30;

      // Renderer setup
      rendererRef.current = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true,
      });
      rendererRef.current.setSize(window.innerWidth, 200); // Adjusted height for footer
      rendererRef.current.setPixelRatio(Math.min(window.devicePixelRatio, 2));
      mountRef.current.appendChild(rendererRef.current.domElement);

      // Create particle system
      const particleCount = 500; // Reduced count for footer
      const geometry = new THREE.BufferGeometry();
      const positions = new Float32Array(particleCount * 3);
      const velocities = new Float32Array(particleCount * 3);

      for (let i = 0; i < particleCount * 3; i += 3) {
        positions[i] = (Math.random() - 0.5) * 50;
        positions[i + 1] = (Math.random() - 0.5) * 20; // Reduced Y spread
        positions[i + 2] = (Math.random() - 0.5) * 50;

        velocities[i] = (Math.random() - 0.5) * 0.05;
        velocities[i + 1] = (Math.random() - 0.5) * 0.05;
        velocities[i + 2] = (Math.random() - 0.5) * 0.05;
      }

      geometry.setAttribute(
        "position",
        new THREE.BufferAttribute(positions, 3)
      );
      geometry.setAttribute(
        "velocity",
        new THREE.BufferAttribute(velocities, 3)
      );

      const material = new THREE.PointsMaterial({
        size: 0.1,
        color: theme === "dark" ? 0xffffff : 0x333333,
        transparent: true,
        opacity: theme === "dark" ? 0.6 : 0.8,
        blending: THREE.AdditiveBlending,
      });

      particlesRef.current = new THREE.Points(geometry, material);
      sceneRef.current.add(particlesRef.current);
    };

    const animate = () => {
      frameIdRef.current = requestAnimationFrame(animate);

      if (particlesRef.current) {
        const positions = particlesRef.current.geometry.attributes.position;
        const velocities = particlesRef.current.geometry.attributes.velocity;

        for (let i = 0; i < positions.count; i++) {
          positions.array[i * 3] += velocities.array[i * 3];
          positions.array[i * 3 + 1] += velocities.array[i * 3 + 1];
          positions.array[i * 3 + 2] += velocities.array[i * 3 + 2];

          // Boundary check and reset
          if (Math.abs(positions.array[i * 3]) > 25)
            velocities.array[i * 3] *= -1;
          if (Math.abs(positions.array[i * 3 + 1]) > 10)
            velocities.array[i * 3 + 1] *= -1;
          if (Math.abs(positions.array[i * 3 + 2]) > 25)
            velocities.array[i * 3 + 2] *= -1;
        }

        positions.needsUpdate = true;
      }

      rendererRef.current.render(sceneRef.current, cameraRef.current);
    };

    initThree();
    animate();

    const handleResize = () => {
      if (cameraRef.current && rendererRef.current) {
        cameraRef.current.aspect = window.innerWidth / 200;
        cameraRef.current.updateProjectionMatrix();
        rendererRef.current.setSize(window.innerWidth, 200);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      cancelAnimationFrame(frameIdRef.current);
      if (mountRef.current && rendererRef.current) {
        mountRef.current.removeChild(rendererRef.current.domElement);
      }
      if (rendererRef.current) {
        rendererRef.current.dispose();
      }
    };
  }, [theme]);

  const logo = theme === "dark" ? logoDark : logoWhite;

  return (
    <div
      id="footer"
      style={{
        position: "relative",
        background:
          theme === "dark"
            ? "linear-gradient(to bottom, #1a1a1a, #0a0a0a)"
            : "linear-gradient(to bottom, #f8f9fa, #e9ecef)",
        padding: "40px 0 20px",
        color: theme === "dark" ? "rgba(255,255,255,0.7)" : "rgba(0,0,0,0.7)",
      }}
    >
      <div
        ref={mountRef}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          pointerEvents: "none",
        }}
      />

      <Container className="position-relative">
        <Row className="mb-4">
          <Col lg={4}>
            <div className="mb-3">
              <h6 style={{ color: "#ffffff" }}>
                <img
                  alt="sennouni sara"
                  src={logo}
                  width={50}
                  className="me-2"
                />
                SENNOUNI SARA
              </h6>
            </div>
            <div className="mb-3">
              <a
                href="#profile"
                className="me-2 text-decoration-none"
                style={{ color: "inherit" }}
              >
                Profile
              </a>
              <a
                href="#education"
                className="me-2 text-decoration-none"
                style={{ color: "inherit" }}
              >
                Education
              </a>
              <a
                href="#experience"
                className="me-2 text-decoration-none"
                style={{ color: "inherit" }}
              >
                Experience
              </a>
              <a
                href="#skills"
                className="text-decoration-none"
                style={{ color: "inherit" }}
              >
                Skills
              </a>
            </div>
          </Col>
          <Col lg={4}>
            <div className="mb-2">
              <LocationOnOutlinedIcon color="primary" className="me-2" />
              Boulevard Laymoune - Morocco, Casablanca
            </div>
            <div className="mb-2">
              <PhoneIphoneOutlinedIcon color="primary" className="me-2" />
              +212 698-662949
            </div>
            <div className="mb-2">
              <ContactMailOutlinedIcon color="primary" className="me-2" />
              sarasennouni@outlook.com
            </div>
          </Col>
          <Col lg={4}>
            <h6 className="mb-3" style={{ color: "#ffffff" }}>
              About me
            </h6>
            <p className="mb-3">Software developer</p>
            <div className="social-icons">
              <a
                href="https://drive.google.com/file/d/1EezWAinaokrZhOF-yMitb2chqUBJay6M/view?usp=share_link"
                className="me-3"
              >
                <CodeOutlinedIcon color="primary" fontSize="large" />
              </a>
              <a href="https://github.com/sennounisara" className="me-3">
                <GitHubIcon color="primary" fontSize="large" />
              </a>
              <a
                href="https://drive.google.com/file/d/1EezWAinaokrZhOF-yMitb2chqUBJay6M/view?usp=share_link"
                className="me-3"
              >
                <FacebookIcon color="primary" fontSize="large" />
              </a>
              <a href="https://www.linkedin.com/in/sara-sennouni-0722b7135/">
                <LinkedInIcon color="primary" fontSize="large" />
              </a>
            </div>
          </Col>
        </Row>
        <div
          className="text-center mt-4"
          style={{
            borderTop: "1px solid rgba(255,255,255,0.1)",
            paddingTop: "20px",
          }}
        >
          Copyright © 2019–{new Date().getFullYear()}. All rights reserved
        </div>
      </Container>
    </div>
  );
};

export default Footer;
