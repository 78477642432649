import React, { useContext } from "react";
import { Container, Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import "./style.css";
import "../../themes/css/timeLine.css";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import Timeline from "@mui/lab/Timeline";
import { timelineItemClasses } from "@mui/lab";
import SchoolIcon from "@mui/icons-material/School";
import { ThemeContext } from "../../context/ThemeContext";

const Education = () => {
  const { theme } = useContext(ThemeContext);

  const timelineStyles = {
    dot: {
      backgroundColor: "#007bff",
      "& .MuiSvgIcon-root": {
        color: "#ffffff",
      },
    },
    connector: {
      backgroundColor: "rgba(255,255,255,0.2)",
    },
    content: {
      background: "rgba(255,255,255,0.05)",
      backdropFilter: "blur(10px)",
      border: "1px solid rgba(255,255,255,0.1)",
      borderRadius: "8px",
      padding: "20px",
      transition: "transform 0.3s ease",
      "&:hover": {
        transform: "translateY(-5px)",
      },
    },
  };

  return (
    <div
      id="education"
      className="position-relative"
      style={{
        minHeight: "100vh",
        background:
          theme === "dark"
            ? "linear-gradient(to bottom, #1a1a1a, #0a0a0a)"
            : "linear-gradient(to bottom, #f8f9fa, #e9ecef)",
        padding: "80px 0",
      }}
    >
      <Container>
        <div className="text-center mb-5">
          <h2
            className="display-4 mb-3"
            style={{
              color: theme === "dark" ? "#ffffff" : "#212529",
              letterSpacing: "2px",
              fontWeight: "bold",
            }}
          >
            Education
          </h2>
          <p
            className="lead"
            style={{
              fontSize: "1.2rem",
            }}
          >
            An overview of my educational background, showcasing degrees, and
            the knowledge I've acquired along the way.
          </p>
        </div>
        <Row>
          <Col lg={6} md={6}>
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot sx={timelineStyles.dot}>
                    <SchoolIcon fontSize={"small"} />
                  </TimelineDot>
                  <TimelineConnector sx={timelineStyles.connector} />
                </TimelineSeparator>
                <TimelineContent sx={timelineStyles.content}>
                  <h5
                    style={{
                      color: theme === "dark" ? "#ffffff" : "#212529",
                      fontWeight: "bold",
                      marginBottom: "0.5rem",
                    }}
                  >
                    Self-taught
                  </h5>
                  <p
                    style={{
                      color:
                        theme === "dark" ? "rgba(255,255,255,0.7)" : "#212529",
                      marginBottom: "0.5rem",
                      fontWeight: "bold",
                    }}
                  >
                    2014 - present
                  </p>
                  <p
                    style={{
                      color:
                        theme === "dark" ? "rgba(255,255,255,0.7)" : "#212529",
                      lineHeight: "1.6",
                    }}
                  >
                    I have a strong foundation in programming concepts and a
                    deep passion for learning new technologies. Through online
                    resources, tutorials and practical experiences.
                    <br />I have developed experience in Web, mobile
                    development. I have worked in variety of personal and
                    professional projects.
                  </p>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </Col>
          <Col lg={6} md={6}>
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot sx={timelineStyles.dot}>
                    <SchoolIcon fontSize={"small"} />
                  </TimelineDot>
                  <TimelineConnector sx={timelineStyles.connector} />
                </TimelineSeparator>
                <TimelineContent sx={timelineStyles.content}>
                  <h5
                    style={{
                      color: theme === "dark" ? "#ffffff" : "#212529",
                      fontWeight: "bold",
                      marginBottom: "0.5rem",
                    }}
                  >
                    EMSI
                  </h5>
                  <p
                    style={{
                      color:
                        theme === "dark" ? "rgba(255,255,255,0.7)" : "#212529",
                      marginBottom: "0.5rem",
                      fontWeight: "bold",
                    }}
                  >
                    2018 - 2020
                  </p>
                  <p
                    style={{
                      color:
                        theme === "dark" ? "rgba(255,255,255,0.7)" : "#212529",
                      lineHeight: "1.6",
                    }}
                  >
                    The Moroccan School of Engineering Sciences, EMSI, is a
                    higher education institution founded in 1986 by a group of
                    university teachers to train students in the fields of
                    engineering, new technologies and telecommunications in
                    Morocco.
                  </p>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Education;
